import React, { Component } from "react";
import {
  Layout,
  PortfolioNav,
  LazySpinner,
  PortfolioHeader,
} from "../../../components";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import LazyLoad from "react-lazyload";
import locales from "../../../constants";

class GMR extends Component {
  render() {
    const lang = "en";
    const nav = {
      initialSlideIndex: 1,
    };
    const { slug } = this.props.pageContext;
    return (
      <Layout
        header={{
          background:
            "linear-gradient( 45deg, #325426, #447730, #558B3F, #447730, #325426 )",
          icons: "#64a34a",
          navClass: "gmr",
        }}
        seo={{
          title: "Green Mountain Resort",
          headerTitle: "gmr",
          href: slug,
          lang: "en",
          ogImage: require("../../../assets/img/portfolio/gmr_top_back.jpg"),
        }}
        langSwitch={{
          langKey: "pl",
          langSlug: "/projekty/green-mountain-resort/",
        }}
      >
        <PortfolioHeader name="gmr" height="252" />
        <section className="container-fluid gmr_section_2" id="info">
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Green Mountain Resort</h1>
                <ul>
                  <li>Website</li>
                  <li>Presentation</li>
                  <li>Catalogues</li>
                  <li>Lead Generation Campaign</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner">
                <p>
                  The real estate developer belongs to the Osada Śnieżka Group,
                  which includes the following complexes: Osada Śnieżka in
                  Łomnica, Osada Śnieżka Medical Spa in Łomnica, Blue Mountain
                  Resort in Szklarska Poręba, Platinum Mountain Resort in
                  Szklarska Poręba, Green Mountain Resort in Karpacz and Crystal
                  Mountain Resort in Vistula.
                </p>
                <p>
                  These are high-class hotel complexes operating in the condo
                  system that guarantee constant profit with high ROI. Green
                  Mountain Resort is located in the heart of the Giant Mountains
                  in Poland and offers exclusive hotel rooms and apartments
                  close to nature. Our Agency was involved in the whole process
                  of advertising and promoting of the investment.
                </p>
                <p>
                  Our cooperation included activities supporting sales of the
                  Green Mountain Resort investment. The scope of cooperation
                  included; developing a functional layout of the website, along
                  with the design and its implementation. We were also
                  responsible for the success in the preparation of sales
                  support materials (presentations, catalogues) and for creating
                  and running a lead generation campaign, which we supported by
                  actively running the hotel’s fanpage.
                </p>
                <p>Goal:</p>
                <ol>
                  <li>
                    Create tools for presenting the offer (website,
                    presentations, catalogues)
                  </li>
                  <li>Provide sales support</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_3">
          <div className="row">
            <div className="col-md-5 offset-md-1">
              <div className="inner">
                <h3>Internet Service</h3>
                <p>
                  The website of{" "}
                  <a
                    href="https://green-mountain-resort.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://green-mountain-resort.pl/
                  </a>{" "}
                  was created for a real estate developer operating on the
                  aparthotel investment market. The most important challenge we
                  had to face while designing the site was to create a unique
                  graphic that would show the prestige of the investment - a
                  high-standard hotel, located in one of the most popular
                  holiday resorts in Poland, near the Śnieżka Mountain and in
                  the vicinity of “Krucze Skały”, which are a big attraction for
                  the mountain climbing enthusiasts.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <div className="scroll_wrapper">
                <span />
                <div className="img_wrapper">
                  <img
                    src={require("../../../assets/img/portfolio/gmr_entire_page.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_4">
          <div className="row no-gutters">
            <div className="col-md-5">
              <img
                className="img-fluid img_calc"
                src={require("../../../assets/img/portfolio/gmr_main_2.png")}
                alt=""
              />
            </div>
            <div className="col-md-6 offset-md-1">
              <p>
                We also wanted to focus on presenting a very wide range of the
                services and recreation that the hotel has in its offer.
              </p>
              <div className="row no-gutters">
                <div className="col-md-6">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/img/portfolio/gmr_main_3.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-6">
                  <img
                    className="img-fluid img_right"
                    src={require("../../../assets/img/portfolio/gmr_main_4.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_5">
          <div className="row">
            <div className="col-md-5 offset-md-3">
              <p>
                The graphic design was also determined by maximizing the
                usability of the website and, most importantly, achieving the
                Client's business goals. The most important function of the site
                was to be obtaining leads from potential customers. All contact
                forms on the site were connected to an external system for
                automated query handling.
              </p>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_6">
          <p>
            In addition to the site’s design, we also coded it to a fully
            functioning website, to which we have also installed the necessary
            analytical tools - including, among others, Google Analytics and
            Google Tag Manager. This made it possible to track the sources of
            inquiries while increasing the effectiveness of When's marketing
            campaign.
          </p>
          <LazyLoad once height={500} placeholder={<LazySpinner />}>
            <video
              width="100%"
              height="100%"
              loop
              autoPlay
              muted
              data-setup="{}"
            >
              <source
                src={require("../../../assets/video_portfolio/GMR_video.webm")}
                type="video/webm"
              />
              <source
                src={require("../../../assets/video_portfolio/GMR_video.mp4")}
                type="video/mp4"
              />
            </video>
          </LazyLoad>
        </section>

        <section className="container-fluid gmr_section_7">
          <div className="row">
            <div className="col-md-5 offset-md-1">
              <div className="inner">
                <h3>Lead Generation Campaigns</h3>
                <p>
                  We have been running the campaign for 1 year. We treated the
                  first month of the campaign as a test one, in which we
                  analyzed the data illustrating users behavior, the rate of
                  achieving goals set by our Client and the level of conversion.
                  We have run extensive campaigns in Google web search and
                  advertising network all over Poland to reach people who may be
                  interested in buying an apartment in the Green Mountain Resort
                  hotel. We selected appropriate keywords related to the
                  investment market and investment web portal, where we have
                  displayed our animated advertisements. Our campaigns followed
                  the amount of contact forms sent via website and forms
                  requesting an offer. At the same time we have been running the
                  fanpage and extensive Facebook campaigns - the goal of the
                  campaign was also to acquire leads and build users' awareness
                  of the desired images regarding the prestige of the investment
                  offered by our Client. You can find the examples of
                  advertising materials we have created below:
                </p>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/gmr_main_6.png")}
                alt=""
              />
            </div>
          </div>
        </section>

        <section
          className="container-fluid gmr_section_8"
          style={{ marginTop: 0 }}
        >
          <div className="row">
            <div className="col-md-6 text-right">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/gmr_main_7.png")}
                alt=""
              />
            </div>
            <div className="col-md-4 offset-md-1">
              <div className="inner">
                <p>
                  Based on the analysis of the campaign's potential, we have
                  recommended more than doubling the budget. This led to the
                  final success - the sale of all apartments.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_9">
          <div className="row">
            <div className="col-md-5 offset-md-1">
              <h3>Materials supporting sales</h3>
              <p>
                The sale begins only when the leads are transferred to our
                Client. The Agency's task is to provide valuable contacts to
                potential investors - this becomes the basis for further actions
                on the side of the developer. To assist the Client in this task,
                we have prepared a sales support presentation - an electronic
                and printed presentation that was used by the client during the
                business meetings with the investors. We have prepared both
                presentations in three language versions - Polish, German and
                English.
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/gmr_main_8.png")}
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_10">
          <div className="row">
            <div className="col-md-5 ">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/gmr_main_9.png")}
                alt=""
              />
            </div>
            <div className="col-md-5">
              <p>
                Our job was to create an attractive project supporting sales and
                presenting the offer of the Green Mountain Resort. The project
                was to evoke specific emotions and increase the desire in the
                potential customers to actually make an investment, by
                emphasizing the prestige of the place and attractive conditions
                of cooperation. The presentation was to be visually consistent
                with the website’s design - in this way we created a coherent
                image of the Client, strengthen communicates in the awareness of
                the recipients and create links between all elements of the
                promotion. We present sample pages from the presentation below.
              </p>
            </div>
          </div>
        </section>

        <section className="container-fluid gmr_section_11">
          <h3>Result of our work:</h3>
          <p className="desc">
            We have created effective and consistent materials presenting the
            developer's offer. We ran an effective campaign, thanks to which all
            available apartments in the investment were sold - investors decided
            to buy 137 hotel rooms and 130 apartments. Interest in the
            facilities offered has also translated into the direct sale of the
            developer's next investment project - Platinum Mountain Resort.
            Thanks to effective actions, the scope of cooperation between us and
            our Client has been extended to further developer's investment
            projects, which are supported by our activities.
          </p>
          <div className="row custom_row" id="counter">
            <TrackVisibility offset={300} className="col-sm-4">
              {({ isVisible }) => (
                <>
                  <CountUp
                    className="counter-value"
                    start={0}
                    duration={3}
                    end={isVisible ? 130 : 0}
                  />
                  <p>sold apartaments</p>
                </>
              )}
            </TrackVisibility>

            <TrackVisibility offset={300} className="col-sm-4">
              {({ isVisible }) => (
                <>
                  <CountUp
                    className="counter-value"
                    start={0}
                    duration={3}
                    end={isVisible ? 137 : 0}
                  />
                  <p>sold hotel rooms</p>
                </>
              )}
            </TrackVisibility>
            <TrackVisibility offset={300} className="col-sm-4">
              {({ isVisible }) => (
                <>
                  <CountUp
                    className="counter-value"
                    start={0}
                    duration={3}
                    end={isVisible ? 100 : 0}
                  />
                  <p>of tasks completed</p>
                </>
              )}
            </TrackVisibility>
          </div>
          <hr />
          <h2>SUCCESS!</h2>
        </section>
        <PortfolioNav data={nav} locales={locales[lang]} />
      </Layout>
    );
  }
}

export default GMR;
